// Generated by Framer (b62c1c2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCodeBoundaryForOverrides, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { withGoBack } from "../codeFile/Back_button.tsx";
import * as sharedStyle from "../css/LQpzKm9t0";
const MotionDivWithGoBacktll4yx = withCodeBoundaryForOverrides(motion.div, {nodeId: "i9hk9j_Nf", override: withGoBack, scopeId: "zssqafUbQ"});

const cycleOrder = ["i9hk9j_Nf", "I99lOAVTt"];

const serializationHash = "framer-A5oYv"

const variantClassNames = {I99lOAVTt: "framer-v-pb86o3", i9hk9j_Nf: "framer-v-tll4yx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Filter Bar": "i9hk9j_Nf", "Go Back + Search": "I99lOAVTt"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "i9hk9j_Nf"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "i9hk9j_Nf", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivWithGoBacktll4yx {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-tll4yx", className, classNames)} data-framer-name={"Filter Bar"} layoutDependency={layoutDependency} layoutId={"i9hk9j_Nf"} ref={refBinding} style={{...style}} {...addPropertyOverrides({I99lOAVTt: {"data-framer-name": "Go Back + Search"}}, baseVariant, gestureVariant)}><SVG className={"framer-13sc422"} data-framer-name={"arrow-left"} layout={"position"} layoutDependency={layoutDependency} layoutId={"hJ1xjoIOB"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 16 16\"><path d=\"M 6.667 4 L 2.667 8 L 6.667 12 M 3.333 8 L 13.333 8\" fill=\"transparent\" stroke-width=\"1.33\" stroke=\"var(--token-b9fc5824-face-4d59-a211-732958720b6d, rgb(10, 10, 10)) /* {&quot;name&quot;:&quot;neutral-950&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={12712950218} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-bt4yie"} data-styles-preset={"LQpzKm9t0"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-b9fc5824-face-4d59-a211-732958720b6d, rgb(10, 10, 10)))"}}>Back </motion.p></React.Fragment>} className={"framer-1cteota"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"RBgH5jN_C"} style={{"--extracted-r6o4lv": "var(--token-b9fc5824-face-4d59-a211-732958720b6d, rgb(10, 10, 10))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></MotionDivWithGoBacktll4yx></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-A5oYv.framer-1xft7aw, .framer-A5oYv .framer-1xft7aw { display: block; }", ".framer-A5oYv.framer-tll4yx { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: 22px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-A5oYv .framer-13sc422 { flex: none; height: 16px; position: relative; width: 16px; }", ".framer-A5oYv .framer-1cteota { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-A5oYv.framer-tll4yx { gap: 0px; } .framer-A5oYv.framer-tll4yx > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-A5oYv.framer-tll4yx > :first-child { margin-left: 0px; } .framer-A5oYv.framer-tll4yx > :last-child { margin-right: 0px; } }", ".framer-A5oYv.framer-v-pb86o3.framer-tll4yx { height: 54px; }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 53
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"I99lOAVTt":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerzssqafUbQ: React.ComponentType<Props> = withCSS(Component, css, "framer-A5oYv") as typeof Component;
export default FramerzssqafUbQ;

FramerzssqafUbQ.displayName = "V2/Back";

FramerzssqafUbQ.defaultProps = {height: 22, width: 53};

addPropertyControls(FramerzssqafUbQ, {variant: {options: ["i9hk9j_Nf", "I99lOAVTt"], optionTitles: ["Filter Bar", "Go Back + Search"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerzssqafUbQ, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})